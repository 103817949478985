import moment from "moment";
import { DATE_FORMAT } from "src/constants";
import { UserProfile } from "src/services/types";
import { overjetReviewColorLightMode } from "src/constants/color-constants";
import { fabric } from "fabric";
import { FlipEnum } from "../new-design/canvas/types";
import { POLYGON_TYPES } from "../new-design/ortho/types";

export function makeBold(input: any, wordsToBold: any) {
  return input.replace(
    new RegExp("(\\b)(" + wordsToBold.join("|") + ")(\\b)", "ig"),
    "$1<b>$2</b>$3"
  );
}

/**
 * Get object from nested objects keys
 * @param obj The object
 * @param args The keys to extract
 * @returns Object
 */
export function extractNestedObject(obj: any, ...args: any) {
  return args.reduce((obj: any, level: any) => obj && obj[level], obj);
}

/**
 *
 * @param defaultSpecilty String value of selected specialty filter
 * @returns Return capitalize value of selected specialty filter
 */
export function matchSpecialtyDefault(defaultSpecilty: string) {
  const selectedSpecialtyFilter = specialtyReviewFilter.find(
    (item) => defaultSpecilty.toLocaleLowerCase() === item.toLocaleLowerCase()
  );
  return selectedSpecialtyFilter;
}

export function claimAnalyticsNormalizer(config: any, queuePage = false): any {
  if (queuePage) {
    return config;
  }
  return {
    ...config,
    startDate: moment(config.startDate).format(DATE_FORMAT.QUERY_DATE_FORMAT),
    endDate: moment(config.endDate).format(DATE_FORMAT.QUERY_DATE_FORMAT),
  };
}

export function getClaimLineItem(claimItem: any) {
  const claimLineItem: any = {};
  if (claimItem) {
    claimLineItem.id = claimItem.id;
    claimLineItem.ingressType = claimItem.ingressType ?? null;
    claimLineItem.pCode = claimItem.procedure;
    claimLineItem.displayProcedure = claimItem.displayProcedure;
    claimLineItem.overjetReviewResult = claimItem.overjetReviewResult;
    claimLineItem.claimId = claimItem.claimId;
    claimLineItem.quadrant = claimItem.quadrant ?? null;
    claimLineItem.claimTooth = claimItem.toothNumber ?? null;
    claimLineItem.arch = claimItem.arch ?? null;
    claimLineItem.pendCode = claimItem.pendCode ?? null;
    claimLineItem.record = claimItem.record ?? null;
  }
  return claimLineItem;
}

export const specialtyReviewFilter = ["All", "Specialty", "Non-Specialty"];

export const claimTitles: any = {
  Total: "#4D4F5C",
  CR: overjetReviewColorLightMode.CLINICAL_REVIEW,
  DR: overjetReviewColorLightMode.DOCUMENTATION_REQUEST,
  AP: overjetReviewColorLightMode.APPROVAL_PROCESSING,
  D: overjetReviewColorLightMode.DENY,
  PD: overjetReviewColorLightMode.PARTIAL_DENY,
  P: overjetReviewColorLightMode.PEND,
  A: overjetReviewColorLightMode.ACCEPT,
  R: overjetReviewColorLightMode.REVIEW,
  C: "#66BB6A",
  PA: overjetReviewColorLightMode.PARTIAL_ACCEPT,
  I: overjetReviewColorLightMode.RFI,
  "Duplicated Procedure": "#4D4F5C",
  "Duplicated - Confidence 90%": "#B71C1C",
  "Duplicated - Confidence 75%": "#F44336",
  "Duplicated - Confidence 50%": "#E65100",
  "All Duplicated": "#4D4F5C",
  "Duplicated - 90%": "#B71C1C",
  "Duplicated - 75%": "#F44336",
  "Duplicated - 50%": "#E65100",
  "Duplicate < 50%": "#FF7043",
  "Clinical Review": overjetReviewColorLightMode.CLINICAL_REVIEW,
  "Documentation Request": overjetReviewColorLightMode.DOCUMENTATION_REQUEST,
  "Approval Processing": overjetReviewColorLightMode.APPROVAL_PROCESSING,
};

export function getTitleColor(key: string): string {
  return claimTitles[key] || "#4D4F5C";
}

export function getPageVersionFromPath(pathname: string): number {
  const matches = pathname.match(/\d+/);
  if (matches && matches.length > 0) {
    return Number(matches[0]);
  }
  return 1;
}

/**
 * Get default route first from client level than to user level
 * @param userProfile {UserProfile} - The client config
 * @returns The route to redirect
 */
export function getDefaultRoute(userProfile: UserProfile): string {
  let route = "";
  if (userProfile && userProfile.clientConfig) {
    const { userDefaultRoute, clientDefaultRoute } = userProfile.clientConfig;
    if (userDefaultRoute && typeof userDefaultRoute === "string") {
      if (clientDefaultRoute && typeof clientDefaultRoute === "string") {
        route = clientDefaultRoute;
      }
      route += userDefaultRoute;
    }
  }
  return route;
}

export function extractOnlyImageName(imagePath: string) {
  if (!imagePath) return "";
  const splitPath = imagePath.split("/") || [];
  return splitPath[splitPath.length - 1]?.split("?")[0] || "";
}

export function getTextGroup(fabricCanvas: fabric.Canvas | null) {
  return (
    fabricCanvas?.getObjects().filter((obj) => obj.type === "i-text") || []
  );
}
export function getGroup(fabricCanvas: fabric.Canvas | null | undefined) {
  const backgroundImage = fabricCanvas?.backgroundImage as fabric.Image;
  const allObjects = fabricCanvas
    ?.getObjects()
    .filter(
      (obj) =>
        obj.data?.polygonType !== POLYGON_TYPES.RULER &&
        !obj.data?.imageCloseIcon
    );
  if (backgroundImage) allObjects?.push(backgroundImage);

  if (backgroundImage.clipPath) allObjects?.push(backgroundImage.clipPath);

  return new fabric.Group(allObjects);
}

export function calculateZoomLevel(
  fabricCanvas: fabric.Canvas | null,
  newAngle: number
) {
  const canvasWidth = fabricCanvas?.width || 1;
  const canvasHeight = fabricCanvas?.height || 1;
  const backgroundImage: fabric.Image =
    fabricCanvas?.backgroundImage as fabric.Image;
  // Image's width and height
  const imgWidth = backgroundImage?.width || 1;
  const imgHeight = backgroundImage?.height || 1;

  // Calculate the bounding box of the rotated image
  const radian = fabric.util.degreesToRadians(newAngle);
  const cos = Math.abs(Math.cos(radian));
  const sin = Math.abs(Math.sin(radian));

  // Calculate the new dimensions of the rotated image's bounding box
  const rotatedWidth = imgWidth * cos + imgHeight * sin;
  const rotatedHeight = imgWidth * sin + imgHeight * cos;

  // Calculate the zoom level to fit the image inside the canvas
  return Math.min(canvasWidth / rotatedWidth, canvasHeight / rotatedHeight);
}

export function rotateCanvas(
  fabricCanvas: fabric.Canvas | null,
  group: fabric.Group,
  angle: number
) {
  const newAngle = (angle + (group.angle || 0)) % 360;
  // Rotate the image grouped with findings
  group.rotate(newAngle);
  const zoomLevel: number = calculateZoomLevel(fabricCanvas, newAngle);
  fabricCanvas?.zoomToPoint(
    group.getPointByOrigin("center", "center") as fabric.Point,
    zoomLevel
  );
  group.setCoords();
  fabricCanvas?.renderAll();

  const textGroup: fabric.Object[] = getTextGroup(fabricCanvas);
  textGroup.forEach((txt) => {
    txt.set({ angle: (txt.angle || 0) + ((angle * -1) % 360) });
  });
  fabricCanvas?.renderAll();
}

export function flipCanvas(
  fabricCanvas: fabric.Canvas | null,
  group: fabric.Group,
  flip: FlipEnum
) {
  const inverted: boolean = Math.abs(group.angle || 0) % 180 === 90;
  if (inverted) {
    flip = flip === "flipY" ? "flipX" : "flipY";
  }
  const groupOptions: Partial<fabric.Group> = {};
  groupOptions[flip] = !group[flip];

  group.set({
    ...groupOptions,
  });
  group.setCoords();
  fabricCanvas?.renderAll();

  // Flip text element position.

  if (inverted) {
    flip = flip === "flipY" ? "flipX" : "flipY";
  }

  const textGroup: fabric.Object[] = getTextGroup(fabricCanvas);
  textGroup.forEach((txt) => {
    const opts: Partial<fabric.Text> = {};
    opts[flip] = !txt[flip];
    txt.set(opts);
  });
  fabricCanvas?.renderAll();
}
