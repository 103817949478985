import { notification } from "antd";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  Observable,
} from "apollo-boost";
import { onError } from "apollo-link-error";

import { cleanTypenameLink } from "src/configs/cleanTypenameLink";
import { ERROR_MSGS } from "src/constants/errors";
import authService from "src/services/auth.service";
import cacheService from "src/services/cache.service";
import userService from "src/services/user.service";
import { client as apolloClient } from "src/App";
import { OperationDefinitionNode } from "graphql/language/ast";

export function initializeApollo(uri: string | undefined) {
  if (!uri) {
    throw new Error(ERROR_MSGS.GRAPHQL_URI_MISSING);
  }

  const logoutLink = onError(({ networkError }) => {
    if (
      typeof networkError === "object" &&
      networkError !== null &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      if (authService.getAuth()) {
        authService.saveUserInfo().then(async (backup) => {
          authService.signOut().finally(() => {
            cacheService.setItemSync("logoutBackup", backup);
            notification.error({
              description: "You must login to proceed with application",
              message: "Session Timeout",
            });
            setTimeout(() => {
              window.location.href = window.location.origin;
            }, 500);
          });
        });
      }
    }
  });
  const mutationInterceptor = new ApolloLink((operation, forward) => {
    const originalRequestObserver = forward(operation);
    const requestClientId: string =
      operation.getContext().headers["x-oj-client-id"];
    const activeClientId: string = userService.getActiveClientId();
    const graphQlOperationName = (
      operation.query?.definitions?.[0] as OperationDefinitionNode
    )?.name?.value;
    const ALLOWED_MUTATION_OPERATIONS = [
      "claimLevelUpdateInsuranceClaimProcedure",
    ];
    const secondaryClientId = userService.getSecondaryWritesClientId();
    if (
      secondaryClientId &&
      activeClientId === requestClientId &&
      graphQlOperationName &&
      ALLOWED_MUTATION_OPERATIONS.includes(graphQlOperationName)
    ) {
      apolloClient
        .mutate({
          mutation: operation.query, // Pass the mutation document from cloned operation
          variables: operation.variables, // Pass the variables from cloned operation
          context: {
            ...operation.getContext(),
            headers: {
              ...operation.getContext().headers,
              "x-oj-client-id": secondaryClientId,
            },
          }, // Pass the context (with modified headers)
        })
        .catch((err) => console.log("Error in duplicated mutation:", err));
    }
    return originalRequestObserver;
  });

  const link = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      // Ensure Firebase is initialized
      authService
        .getFirebaseToken()
        .then((accessToken) => {
          const gcpTenantId = authService.getAuth()?.gcpTenantId;
          const activeClientId = userService.getActiveClientId();

          let headers: any = {
            Authorization: `Bearer ${accessToken}`,
            "x-oj-client-id": activeClientId,
          };

          if (gcpTenantId) {
            headers = {
              ...headers,
              "X-OJ-TENANT-ID": gcpTenantId,
            };
          }
          if (!operation.getContext().headers)
            operation.setContext({ headers });
          const subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });

          return () => {
            if (subscription) subscription.unsubscribe();
          };
        })
        .catch((error) => {
          console.error("Error getting token", error);
          observer.error(error);
        });
    });
  })
    .concat(mutationInterceptor)
    .concat(new HttpLink({ uri }));

  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: from([cleanTypenameLink, logoutLink, link]),
  });
  return client;
}
